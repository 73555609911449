import { render, staticRenderFns } from "./ChartsGroup.vue?vue&type=template&id=5b0a77d8&scoped=true&lang=pug&"
import script from "./ChartsGroup.vue?vue&type=script&lang=js&"
export * from "./ChartsGroup.vue?vue&type=script&lang=js&"
import style0 from "./ChartsGroup.vue?vue&type=style&index=0&id=5b0a77d8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0a77d8",
  null
  
)

export default component.exports