<template lang="pug">
Skeleton(v-if="isLoading")

ul.charts-list(v-else)
    li(v-for="chart in selectedCharts" :key="chart.id")
      PriceAnalysisChart(
              :title="chart.title"
              :chart-id="chart.id"
              :drop-down="chart.dropDown"
              :request-params="requestParams"
              :additionalData="chartsWidgetData"
              :is-additional-data="chart.id === 'DYNAMICS_OF_DELTA_RRP'"
          )

</template>

<script>
import Skeleton from '@/components/Nestle/Skeleton/Common.vue';
import PriceAnalysisChart from './PriceAnalysisChart.vue';

export default {
    components: {
        Skeleton,
        PriceAnalysisChart,
    },

    props: {
        requestParams: {
            type: Object,
            required: true,
        },
        needUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            chartsIds: [],
            chartsWidgetData: null,
            chartsTypes: [
                {
                    id: 'DYNAMICS_OF_PRICES',
                    title: 'Регулярная цена, ₽',
                    dropDown: true
                },
                {
                    id: 'DYNAMICS_OF_PROMO_PRICE',
                    title: 'Промо цена, ₽',
                    dropDown: true
                },
                {
                    id: 'DYNAMICS_OF_DISCOUNTS',
                    title: 'Глубина скидки, %',
                },
                {
                    id: 'DYNAMICS_OF_DELTA_RRP',
                    title: 'Отклонение от РРЦ, %',
                },
                {
                    id: 'DYNAMICS_PRICE_INDEX',
                    title: 'Ценовой индекс, %',
                    dropDown: true,
                },
                {
                  id: 'DYNAMICS_SHARE_GOODS_DEVIATION_RRP',
                  title: 'Доля товаров с отклон. от РРЦ',
                }
            ],
        }
    },

    computed: {
        selectedCharts() {
            return this.chartsTypes.filter((chart) => this.chartsIds.includes(chart.id));
        },
    },

    methods: {
        async fetchCharts() {
            try {
                const response = await this.$api.purina.getPriceAnalysisCharts();
                const isResponseValid = response && Array.isArray(response.charts);
                this.chartsIds = isResponseValid ? response.charts : [];
            } catch {
                this.chartsIds = [];
            }
        },

        async fetchChartsStatisticData() {
            try {
                const response = await this.$api.purina.getPriceAnalysisWidgetsData(this.requestParams);
                this.chartsWidgetData = response;
            } catch {
                this.chartsWidgetData = null;
            }
        },

        async fetchData() {
            this.isLoading = true;

            await Promise.all([
                this.fetchCharts(),
                this.fetchChartsStatisticData()
            ]);

            this.isLoading = false;
        },
    },

    watch: {
        async needUpdate() {
            await this.fetchData();
        },
    },

    async mounted() {
        await this.fetchData();
    }
}
</script>

<style scoped lang="scss">
.charts-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}
</style>
